<template>
	<div>
		<div class="header-section icon-list detailed">
			<div class="header-with-tabs row-space-tbf">
            	<div class="space-left"></div>
                <div class="content">
                    <slot />
                    <div class="actions" v-if="soft_procedures.is_active">
                    	<button class="btn-tbf blue btn-excel center" v-bind:class="{'only-icon': $resize && $mq.below(1299)}" @click="donwloadXLS">
                            <span class="text" v-if="$resize && $mq.above(1300)">{{ $t('reports.download_xls') }}</span>
                            <icon-download class="icon" v-else />
                        </button>
                    </div>
                </div>
                <div class="space-right"></div>
            </div>
		</div>

		<template v-if="soft_procedures.is_active">
			<template v-if="loadedList">
				<div class="data-list list-goals" v-if="processes.length">
					<div class="row-space-tbf header-list">
						<div class="space-left"></div>
						<div class="content">
							<div class="column-filter sortable column-name" @click="sortList('name')" v-bind:class="[sortBy === 'name' ? 'sort ' + sortDirection : '']">
								<div class="text">{{ $t('processes.name') }}</div>
								<div class="icon-filter"><icon-arrow /></div>
							</div>
							<div v-if="$resize && $mq.above(572)" class="column-filter column-versions">
								<div class="text">{{ $t('processes.versions') }}</div>
							</div>
							<div v-if="$resize && $mq.above(470)" class="column-filter column-version-name">
								<div class="text">{{ $t('processes.active_version') }}</div>
							</div>
							<div v-if="$resize && $mq.above(470)" class="column-filter column-procedures-count">
								<div class="text">{{ $t('processes.procedures') }}</div>
							</div>
							<div v-if="$resize && $mq.above(830)" class="column-filter column-update_requests">
								<div class="text">{{ $t('processes.update_requests') }}</div>
							</div>
							<div class="column-filter column-user"></div>
						</div>
						<div class="space-right"></div>
					</div>

					<template v-for="processItem in processes">
						<div class="row-space-tbf row-list-item" :key="processItem.id">
							<div class="space-left"><icon-chart v-if="$resize && $mq.above(781)"/></div>
							<div class="content">
								<div class="border-hover" v-if="$resize && $mq.above(781)"></div>
								<div class="column-name-desc column-name" @click="viewProcess(processItem.slug)">
									<div class="name">{{ processItem.name }}</div>
								</div>
								<div v-if="$resize && $mq.above(572)" class="column-simple-text column-versions">
									<div class="text">{{ processItem.total_versions }}</div>
								</div>
								<div v-if="$resize && $mq.above(470)" class="column-simple-text column-version-name">
									<div class="text">{{ processItem.publish_version.name }}</div>
								</div>
								<div v-if="$resize && $mq.above(470)" class="column-simple-text column-procedures-count">
									<div class="text">{{ processItem.publish_version.procedures_count }}</div>
								</div>
								<div v-if="$resize && $mq.above(830)" class="column-simple-text column-update_requests">
									<div class="text">{{ processItem.improvement_proposals.active }}</div>
								</div>
								<div class="column-avatar column-user">
									<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" v-if="processItem.responsible">
										<img :src="processItem.responsible.avatar" v-if="processItem.responsible && processItem.responsible.avatar">
										<div class="user-circle" v-else>
											<icon-user />
										</div>
										<template slot="popover">
											<div class="simple-text">{{processItem.responsible.name}}</div>
										</template>
									</v-popover>
								</div>
							</div>
							<div class="space-right">
								<!-- <div v-if="$resize && $mq.above(781)" class="dropdown dropdown-edit">
									<div class="button-edit" :ref="'dropdownEditRef'+processItem.id" :id="'dropdownEdit'+processItem.id" data-toggle="dropdown">
										<icon-edit />
									</div>
									<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu'+ processItem.id " :aria-labelledby="'dropdownEdit'+processItem.id">
										<div class="dropdown-item" @click="editProcess(processItem.slug)"><div class="simple-text">{{ $t('general.edit') }}</div></div>
										<div class="dropdown-item" @click="showModal('delete', {type: 'process', from: 'index_processes', model: processItem})"><div class="simple-text">{{ $t('general.delete') }}</div></div>
									</div>
								</div> -->
							</div>
						</div>
					</template>
				</div>
				<div class="row-space-tbf list-empty" v-else>
					<div class="space-left"></div>
					<div class="content full">
						<!-- <div class="icon-empty">
							<img src="/build/images/no-results-found.svg">
						</div> -->
						<div class="title">{{ $t('empty.report-title')}}</div>
					</div>
					<div class="space-right"></div>
				</div>
			</template>
			<loader-items-list class="position-absolut-list-loader" v-else/>

			<infinite-loading :identifier="infiniteId" @infinite="infiniteProcesses" ref="infiniteLoading"><div slot="spinner"></div><div slot="no-more"></div><div slot="no-results"></div></infinite-loading>
		</template>
		<template v-else>
			<div class="row-space-tbf">
				<div class="space-left"></div>
				<div class="content">
					<not-module class="with-border-top" />
				</div>
				<div class="space-right"></div>
			</div>
		</template>
	</div>
</template>

<script>
	import NotModule from '@/components/General/NotModule'
    import IconArrow from '../../Icons/Arrow'
    import IconUser from '../../Icons/User'
    import IconPeople from '../../Icons/People'
    import IconEdit from '../../Icons/EditDots'
    import LoaderItemsList from '../../PagesLoaders/ItemsList'
    import IconChart from '../../Icons/Chart'
	import InfiniteLoading from 'vue-infinite-loading'
	import IconDownload from '../../Icons/Download'

    export default {
    	components: {
			NotModule,
            IconArrow,
            IconUser,
            IconPeople,
            IconEdit,
            LoaderItemsList,
            IconChart,
            IconDownload,
			InfiniteLoading
        },
		computed: {
			soft_procedures() {
				return this.$store.getters['applications/getApplication']('Proceduri');
			}
        },
        data() {
            return {
            	loadedList: false,
            	processes: [],
            	sortBy: '',
            	sortDirection: 'asc',
				page: 0,
				infiniteId: 1
            }
        },
        async mounted(){
        	if( this.soft_procedures.is_active ){ 
				this.$refs.infiniteLoading.status = 1
				this.$refs.infiniteLoading.$emit('infinite', this.$refs.infiniteLoading.stateChanger)
			}
        },
        methods: {
            infiniteProcesses($state){
				var paramsCall = { view_more: this.page * 10}
				if(this.sortBy){
        			paramsCall.sort_by = this.sortBy
        			paramsCall.sort_direction = this.sortDirection
        		}
        		
				axios.get('/reports/' + this.$auth.user().instance.id + '/processes', {params: paramsCall})
				.then(({data}) => {
                    if(data.data.length) {
                        this.page++
						this.processes.push(...data.data)
						setTimeout(() => { $state.loaded() }, 200)
        				if(data.data.length < 10){
        					$state.complete()
        				}
                    } else{
						$state.complete()
					}

        			this.loadedList = true
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			sortList(column){
				if(column === this.sortBy) {
                    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
                }
                this.sortBy = column;
                this.searchFilterFunct()
			},
			editProcess(processSlug){
				this.$router.push({ name: 'process-edit', params: {slug: processSlug} })
			},
			viewProcess(processSlug){
				this.$router.push({ name: 'process-show', params: {slug: processSlug} })	
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			donwloadXLS() {
				var showColumnsObj = ['name', 'total_versions', 'publish_version.name', 'publish_version.procedures_count', 'improvement_proposals.active']

				var paramsCall = {}
				paramsCall.type = 'xls'
				paramsCall.show_columns = showColumnsObj
				
				axios.get('/reports/' + this.$auth.user().instance.id + '/processes', 
				{
					params : paramsCall,
                	responseType: 'blob'
				})
				.then((response) => {

					let blob = new Blob([response.data], { type:"application/octet-stream" })
					let fileURL = window.URL.createObjectURL(blob);

					let fileName = this.$t('reports.processes') + '.xlsx';

					let fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute( 'download', fileName );
					document.body.appendChild(fileLink);

					fileLink.click();
				})
            },
            searchFilterFunct(){
				this.loadedList = false
				this.page = 0
				this.processes = []
				this.infiniteId += 1
			}
        }
    }
</script>

<style lang="scss">
	.list-goals{
		.view-more{
            width: 100%;
            a{
                width: 100%;
                height: 38px;
                .text{
                    color: $grey;
                }
            }
        }
		.row-list-item{
			&:hover{
				.content{
					.column-name{
						.name{
							font-weight: 700;
						}
					}
				}
			}
		}
		.column-name{
			flex: 1 1 auto;
			cursor: pointer;
		}
		.column-versions{
			flex: 0 0 150px;
		}
		.column-version-name{
			flex: 0 0 130px;
		}
		.column-procedures-count{
			flex: 0 0 100px;
		}
		.column-update_requests {
			flex: 0 0 110px;
		}
		.column-user{
			flex: 0 0 90px;
			display: flex;
			justify-content: flex-end;
		}
		@media (max-width: 970px) {
			.column-versions,
			.column-version-name,
			.column-procedures-count,
			.column-update_requests{
				flex: 0 0 100px;
			}
			.column-user{
				flex: 0 0 37px;
			}
		}
	}
	.list-empty .content {
		padding: 10px;
		padding-left: 0px;
		border-top: none;
		color: $grey
	}
</style>