<template>
	<div class="row-space-tbf detailed" v-if="loaded">
		<div class="space-left"></div>
        <processes v-if="activeTab == 'processes'" >
            <tabs-menu activeTab="processes" />
        </processes>
        <procedures v-if="activeTab == 'procedures'" >
            <tabs-menu activeTab="procedures" />
        </procedures>
        <improvements v-if="activeTab == 'improvements'" >
            <tabs-menu activeTab="improvements" />
        </improvements>
        <users v-if="activeTab == 'users'">
            <tabs-menu activeTab="users" />
        </users>
		<div class="space-right"></div>
	</div>
</template>

<script>
    import Improvements from './Improvements'
	import Procedures from './Procedures'
	import Processes from './Processes'
	import Users from './Users'
	import TabsMenu from './TabsMenu'

    export default {
    	components: {
            Improvements,
			Procedures,
            Processes,
            Users,
            TabsMenu
        },
        watch:{
			$route (to, from){
				this.activeTab = this.$route.query.type ? this.$route.query.type : 'processes'
            }
        },
        data() {
            return {
            	activeTab: 'processes',
                loaded: false
            }
        },
        async mounted(){
            if(this.$route.query.type){
				this.activeTab = this.$route.query.type
                this.$root.$emit('change_'+this.$route.query.type);
			}

            setTimeout(() => {
                var title = this.$t('navbar.reports');
                this.$root.$emit("navbar_title", title);

                setTimeout(() => {
                    this.loaded = true
                }, 1000)
            }, 0)
        },
        methods: {
        	changeTab(value){
        		this.activeTab = value
        	}
        }
    }
</script>